import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronRightIcon } from "@heroicons/react/outline";

export default function NextProject({ current }) {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allContentfulProject(sort: { fields: period, order: DESC }) {
            edges {
              node {
                id
                period
              }
              next {
                id
                client {
                  name
                  logo {
                    gatsbyImageData(width: 400)
                  }
                }
                smallCover {
                  gatsbyImageData(width: 100)
                }
                slug
                teaser
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="sticky bottom-0 items-center w-64 lg:w-full snap-center">
          {data.allContentfulProject.edges
            .filter((project) => project.node.id === current.id)
            .map((project) => (
              <div
                key={project.node.id}
                className="flex flex-nowrap w-full shadow-md bg-white rounded-xl lg:rounded-b-none hover:shadow-lg  dark:bg-gray-900 shadow-gray-200 dark:shadow-interaction"
              >
                {project.next ? (
                  <Link
                    key={project.next.id}
                    to={"/shared/portfolio/" + project.next.slug}
                    className="flex flex-row"
                    replace
                  >
                    <div className="">
                      <GatsbyImage
                        image={getImage(project.next.smallCover)}
                        alt="client logo"
                        className="w-24 rounded-l-xl lg:rounded-bl-none"
                      />
                    </div>
                    <div className="flex flex-col align-middle h-full justify-end px-2">
                      <div className="font-Caveat text-2xl py-2 dark:text-white">
                        <span>Up next</span>
                      </div>
                      <div className=" w-full">
                        <p className="text-sm text-gray-500 dark:text-slate-400  w-full line-clamp-3 italic">
                          {project.next.teaser}
                        </p>
                      </div>
                      <div className="flex items-center justify-end  text-sm py-2 ">
                        <span className="font-semibold text-gray-900 dark:text-white">
                          Read case
                        </span>
                        <ChevronRightIcon className="h-6 w-6 text-interaction" />
                      </div>
                    </div>
                  </Link>
                ) : null}
              </div>
            ))}
        </div>
      )}
    />
  );
}
