import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Client from "./client";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CurrentProject({ project, currentHeader }) {
  const menuShortcuts = [
    {
      name: "The Client",
      id: 0,
    },
    {
      name: "The Challenge",
      id: 1,
    },
    {
      name: "The Approach",
      id: 2,
    },
    {
      name: "The Outcome",
      id: 3,
    },
  ];
  return (
    <div className="flex flex-col w-full lg:w-auto px-4 md:px-6 lg:px-0 ">
      <div className="text-sm lg:hidden flex justify-center flex-wrap pb-2">
        <span className="whitespace-nowrap">
          <span className="font-bold pr-1">Client: </span>
          <span>
            {project && project.client && project.client.name
              ? project.client.name
              : ""}
          </span>
          <span className="text-interaction px-2 font-bold">//</span>
        </span>

        <span className="whitespace-nowrap">
          <span className="font-bold pr-1">Role:</span>
          <span>{project.role}</span>
          <span className="text-interaction px-2 font-bold">//</span>
        </span>

        <span className="whitespace-nowrap">
          <span className="font-bold pr-1">Period: </span>
          <span>
            {(project.period ? project.period + " " : "") +
              (project.periodTo ? " - " + project.periodTo : "")}
          </span>
        </span>
      </div>
      <div className="grid gap-2 lg:gap-4 grid-cols-1 lg:py-4 xl:w-56">
        <div className="grid-cols-6 lg:grid-cols-1 gap-2 text-sm hidden lg:grid">
          <div className=" font-bold col-span-2 xs:col-span-1 lg:hidden dark:text-slate-200">
            Client
          </div>
          <div className="text-gray-900 dark:text-slate-400 col-span-4 xs:col-span-5 lg:hidden">
            {project && project.client && project.client.name
              ? project.client.name
              : ""}
          </div>
          <div className="text-gray-900 dark:text-slate-400 col-span-5 xs:col-span-6 hidden lg:flex">
            <Client
              image={
                project && project.client && project.client.logo
                  ? project.client.logo
                  : ""
              }
              title={
                project && project.client && project.client.name
                  ? project.client.name
                  : ""
              }
              variant={"medium"}
            />
          </div>
        </div>
        <div className="grid-cols-6 lg:grid-cols-1 gap-2 text-sm hidden lg:grid">
          <div className=" font-bold col-span-2 xs:col-span-1 dark:text-slate-200">
            Period
          </div>
          <div className="text-gray-900 dark:text-slate-400 flex col-span-4 xs:col-span-5">
            <div className="w-fit">
              {project.period ? project.period + " " : ""}
            </div>
            <div className="w-fit ">
              {project.periodTo ? " - " + project.periodTo : ""}
            </div>
          </div>
        </div>
        <div className="grid-cols-6 lg:grid-cols-1 gap-2 text-sm hidden lg:grid">
          <div className="font-bold col-span-2 xs:col-span-1 dark:text-slate-200">
            Industry
          </div>
          <div className="text-gray-900 dark:text-slate-400 col-span-4 xs:col-span-5">
            {project.industry}
          </div>
        </div>
        <div className=" grid-cols-6 lg:grid-cols-1 gap-2 text-sm hidden lg:grid">
          <div className="font-bold col-span-2 xs:col-span-1 dark:text-slate-200">
            Role
          </div>
          <div className="text-gray-900 dark:text-slate-400 col-span-4 xs:col-span-5">
            {project.role}
          </div>
        </div>
        <div className="grid grid-cols-6 lg:grid-cols-1 gap-2 text-sm">
          <div className="font-bold col-span-1 hidden lg:flex dark:text-slate-200">
            Tags
          </div>
          <div className="flex-wrap flex-row flex col-span-6 justify-center lg:justify-start">
            {project.tags
              ? project.tags.map((tag) => (
                  <div
                    key={project.id + tag}
                    className="bg-gray-200 py-1 px-2 text-xs mr-1 mt-1 rounded-lg text-gray-800 dark:text-gray-900 dark:bg-gray-400 whitespace-nowrap"
                  >
                    {tag}
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className=" flex-col lg:w-full hidden lg:flex">
          <div className="pb-2 font-bold text-sm">Case content</div>
          <div className="">
            {menuShortcuts.map((item) => (
              <Link
                key={item.id}
                to={"#" + item.id}
                className="flex items-center w-fit h-fit group"
                replace
              >
                <div
                  className={classNames(
                    currentHeader == item.id ? "!scale-100" : "",
                    "h-6 bg-gradient-to-br from-[#49b6ff] to-interaction w-1 rounded-full transition-transform scale-0 origin-center group-hover:transform group-hover:scale-100"
                  )}
                ></div>
                <div
                  className={classNames(
                    currentHeader == item.id
                      ? "!text-gray-900 dark:!text-white !font-semibold"
                      : "",
                    "items-center p-2 text-sm font-medium transition-transform dark:text-gray-200 group-hover:transition  group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction"
                  )}
                >
                  {item.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
