import * as React from "react";
import { graphql } from "gatsby";
import PortfolioView from "../../../views/portfolio-view";

function Project({ data, pageContext }) {
  const project = data.contentfulProject;

  return <PortfolioView project={project} key={project.id} />;
}

export const query = graphql`
  query ($id: String) {
    contentfulProject(id: { eq: $id }) {
      client {
        name
        logo {
          gatsbyImageData(width: 400)
        }
      }
      id
      title
      slug
      teaser
      tags
      industry
      role
      period(formatString: "MMM YYYY")
      periodTo(formatString: "MMM YYYY")

      contentChallenge {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 2000, placeholder: BLURRED)
            description
            file {
              contentType
              url
            }
          }
          ... on ContentfulProject {
            contentful_id
            __typename
            title
            slug
          }
        }
      }
      contentClient {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 2000, placeholder: BLURRED)
            description
            file {
              contentType
              url
            }
          }
          ... on ContentfulProject {
            contentful_id
            __typename
            title
            slug
          }
        }
      }
      contentApproach {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 2000, placeholder: BLURRED)
            description
            file {
              contentType
              url
            }
          }
          ... on ContentfulProject {
            contentful_id
            __typename
            title
            slug
          }
        }
      }
      contentOutcome {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 2000, placeholder: BLURRED)
            description
            file {
              contentType
              url
            }
          }
          ... on ContentfulProject {
            contentful_id
            __typename
            title
            slug
          }
        }
      }
      coverphoto {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
    }
  }
`;
export default Project;
