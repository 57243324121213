import * as React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import NextProject from "../components/nextProject";
import CurrentProject from "../components/currentProject";
import Client from "../components/client";
import ContactMenu from "../components/contactMenu";
import Layout from "../components/layout";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
    [MARKS.ITALIC]: (text) => <i>{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => (
      <p className="mb-4 ml-8 text-2xl font-light italic text-gray-600 ">
        {text}
      </p>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="mb-4 leading-relaxed">{children}</p>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="underline underline-offset-2 decoration-black dark:decoration-[#94a3b8] decoration-2 dark:text-slate-400"
        href={node.data.uri}
      >
        {children}
      </a>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <p className="mb-4 leading-relaxed text-gray-900 bg-gray-50 border-l-4 p-4 border-gray-400 rounded-md">
        {children}
      </p>
    ),

    [BLOCKS.HEADING_1]: (node, children) => {
      let string = String(children).replace(/\s+/g, "_");
      return (
        <h1
          className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 pb-4 pt-8"
          id={string}
        >
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      let string = String(children).replace(/\s+/g, "_");
      return (
        <h2
          className="text-xl font-bold tracking-tight text-gray-900 dark:text-slate-200 py-4"
          id={string}
        >
          {children}
        </h2>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-lg font-bold tracking-tight text-gray-900 dark:text-slate-200 py-4">
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc space-y-2 px-8 ">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="text-interaction">
        <div className="text-black dark:text-slate-400 ">{children}</div>
      </li>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ul className="list-decimal space-y-2 px-8">{children}</ul>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.file.contentType === "video/mp4") {
        const videoUrl = node.data.target.file.url;
        return (
          <div className="my-6">
            <video controls>
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        );
      } else if (node.data.target.file.contentType === "text/html") {
        //TODO: find a way to use the url/link instead
        const url = node.data.target.description;
        return (
          <div>
            <div className="hidden print:flex">
              (Example demo hidden in print - visit {url} to see the sandbox)
            </div>
            <div className="mb-4 print:hidden">
              <iframe
                title="Codesandbox"
                src={url}
                width="100%"
                height="600"
              ></iframe>
            </div>
          </div>
        );
      } else if (
        node.data.target.file.contentType === "image/png" ||
        "image/jpeg"
      ) {
        const { gatsbyImageData } = node.data.target;
        return (
          <div className="my-6 ">
            <Zoom>
              <GatsbyImage alt="image" image={gatsbyImageData} />
            </Zoom>
            {node.data.target.description ? (
              <p className="text-sm text-gray-500 dark:text-slate-400 italic">
                {node.data.target.description}
              </p>
            ) : (
              ""
            )}
          </div>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (node.data.target.sys.type === "Entry") {
        return (
          <a
            className="underline underline-offset-2 decoration-black dark:decoration-[#94a3b8] decoration-2 dark:text-slate-400"
            href={`/shared/portfolio/${node.data.target.slug}`}
          >
            {" "}
            {node.data.target.title}
          </a>
        );
      }
    },
  },
};

/*
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}
*/

function PortfolioView({ project }) {
  const [currentHeader, setCurrentHeader] = useState(0);
  const nextProject = useRef(null);
  const contentClient = useRef(null);
  const contentChallenge = useRef(null);
  const contentApproach = useRef(null);
  const contentOutcome = useRef(null);

  const setSelectedOption = (target) => {
    let min;
    if (target.length) {
      min = target.reduce((min, current) => {
        return current < min ? current : min;
      });
    }
    setCurrentHeader(min);
  };
  let curr = [];
  let callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        curr.push(entry.target.id);
      } else {
        if (curr.includes(entry.target.id)) {
          curr.splice(curr.indexOf(entry.target.id), 1);
        }
      }
      setSelectedOption(curr);
    });
  };

  useEffect(() => {
    let contentClientRefValue = null;
    let contentChallengeRefValue = null;
    let contentApproachRefValue = null;
    let contentOutcomeRefValue = null;

    const options = {
      root: null,
      rootMargin: "-64px", //TODO: should be -128px mobile, -64px for larger sizes. https://github.com/w3c/IntersectionObserver/pull/486
      threshold: 0,
    };

    const observer = new IntersectionObserver(callback, options);

    if (
      contentClient.current &&
      contentChallenge.current &&
      contentApproach.current &&
      contentOutcome.current
    ) {
      observer.observe(contentClient.current);
      observer.observe(contentChallenge.current);
      observer.observe(contentApproach.current);
      observer.observe(contentOutcome.current);
      contentClientRefValue = contentClient.current;
      contentChallengeRefValue = contentChallenge.current;
      contentApproachRefValue = contentApproach.current;
      contentOutcomeRefValue = contentOutcome.current;
    }

    return () => {
      if (contentClientRefValue) {
        observer.unobserve(contentClientRefValue);
      }
      if (contentChallengeRefValue) {
        observer.unobserve(contentChallengeRefValue);
      }
      if (contentApproachRefValue) {
        observer.unobserve(contentApproachRefValue);
      }
      if (contentOutcomeRefValue) {
        observer.unobserve(contentOutcomeRefValue);
      }
    };
  }, []);
  const path =
    typeof location !== "undefined" && location.pathname
      ? location.pathname
      : "";

  return (
    <Layout location="/shared/portfolioPage/">
      <div
        key={project.id}
        className="bg-gray-50 print:bg-white dark:bg-gray-900 dark:text-slate-400 pt-16 -mt-16"
      >
        {/* <div className="flex sticky top-0 z-40 bg-white w-full h-16 min-h-fit shadow-sm print:hidden">
          <div className="max-w-screen-xl flex w-full items-center justify-between mx-auto px-4">
            <div className="flex">
              <button
                onClick={() => navigate(-1)}
                className="flex items-center text-interaction p-2 group rounded-lg hover:bg-interaction hover:text-white "
              >
                <ChevronLeftIcon className="h-6 w-6 group-hover:animate-wiggle " />

                <span>Back</span>
              </button>
            </div>
            <div className="flex">
              <ContactMenu />
            </div>
          </div>
        </div> */}
        <div className="bg-white dark:bg-gray-800">
          <div className="lg:h-[75vh] lg:max-h-screen lg:px-6 grid items-center grid-cols-1 gap-x-8 max-w-screen-md mx-auto lg:max-w-screen-xl lg:grid-cols-2">
            <div className="px-4 md:px-6 lg:px-0 mt-4 lg:mt-0">
              <Client
                image={
                  project && project.client && project.client.logo
                    ? project.client.logo
                    : ""
                }
                title={
                  project && project.client && project.client.name
                    ? project.client.name
                    : ""
                }
                variant={"xlarge"}
              />

              <h2 className=" font-extrabold tracking-tight text-gray-900 dark:text-white text-2xl md:text-3xl my-4">
                {project.teaser}
              </h2>
            </div>
            <div className="min-w-0">
              <GatsbyImage
                image={getImage(project.coverphoto)}
                alt="client logo"
                className="object-center md:rounded-xl"
              />
            </div>
            <div className="flex flex-col lg:hidden w-full mx-auto bg-gray-50 print:bg-white dark:bg-gray-800 md:bg-white">
              <div className="flex md:hidden w-full justify-center items-center bg-gradient-to-b from-white dark:from-gray-800 dark:to-gray-900  h-10"></div>
            </div>
          </div>
        </div>
        <div
          //className="w-full justify-center items-center flex flex-col bg-gradient-to-b from-white h-20"
          className="w-full justify-center items-center flex-col hidden md:flex bg-gradient-to-b from-white dark:from-gray-800 h-20"
        >
          <Link
            to={"#0"}
            className={`print:hidden flex-col items-center ${
              currentHeader ? "invisible" : "flex"
            }`}
          >
            <p className="font-Caveat text-xl pb-2">Read the story</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 animate-bounce print:hidden ${
                currentHeader ? "hidden" : "flex"
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </Link>
        </div>

        <div className="flex flex-col lg:flex-row justify-center">
          <div
            className={`hidden xl:flex xl:flex-1 h-[calc(100vh-4rem)] overflow-y-auto w-auto max-w-xs min-w-min sticky top-16 self-start justify-between flex-col ml-8 items-end   ${
              currentHeader ? "lg:animate-fadeIn" : "invisible"
            }`}
          >
            <CurrentProject project={project} currentHeader={currentHeader} />
          </div>
          <div className="flex w-full mx-auto lg:mx-0 min-w-screen-md max-w-screen-md lg:flex-shrink-0 flex-col">
            <div className="lg:mb-8 pt-0 lg:pt-4">
              <div className="flex flex-col lg:hidden w-full bg-gray-50 print:bg-white dark:bg-gray-900">
                <div className="pb-8">
                  <CurrentProject
                    project={project}
                    currentHeader={currentHeader}
                  />
                </div>
              </div>
              {project.contentClient ? (
                <div
                  id={0}
                  ref={contentClient}
                  className="scroll-margin-top-8 md:scroll-margin-top-4 md:rounded-xl pb-8 mb-8 pt-4 md:mx-6 px-4 md:px-8 bg-white dark:bg-gray-800 print:my-0 print:py-0"
                >
                  <div className="flex items-center flex-col w-fit pt-4 pb-4">
                    <h2 className="text-2xl font-bold dark:text-slate-200">
                      The Client
                    </h2>
                    <div className="w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-1 rounded-full"></div>
                  </div>

                  {renderRichText(project.contentClient, options)}
                </div>
              ) : (
                ""
              )}
              {project.contentChallenge ? (
                <div
                  id={1}
                  ref={contentChallenge}
                  className="scroll-margin-top-8 md:scroll-margin-top-4 md:rounded-xl pb-8 mb-8 pt-4 md:mx-6 px-4 md:px-8 bg-white dark:bg-gray-800 print:my-0 print:py-0"
                >
                  <div className="flex items-center flex-col w-fit pt-4 pb-4">
                    <h2 className="text-2xl font-bold dark:text-slate-200">
                      The Challenge
                    </h2>
                    <div className="w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-1 rounded-full"></div>
                  </div>
                  {renderRichText(project.contentChallenge, options)}
                </div>
              ) : (
                ""
              )}
              {project.contentApproach ? (
                <div
                  id={2}
                  ref={contentApproach}
                  className="scroll-margin-top-8 md:scroll-margin-top-4 md:rounded-xl pb-8 mb-8 pt-4 md:mx-6 px-4 md:px-8 bg-white dark:bg-gray-800 print:my-0 print:py-0"
                >
                  <div className="flex items-center flex-col w-fit pt-4 pb-4">
                    <h2 className="text-2xl font-bold dark:text-slate-200">
                      The Approach
                    </h2>
                    <div className="w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-1 rounded-full"></div>
                  </div>
                  {renderRichText(project.contentApproach, options)}
                </div>
              ) : (
                ""
              )}
              {project.contentOutcome ? (
                <div
                  id={3}
                  ref={contentOutcome}
                  className="scroll-margin-top-8 md:scroll-margin-top-4 md:rounded-xl pb-8 lg:mb-8 pt-4 md:mx-6 px-4 md:px-8 bg-white dark:bg-gray-800 print:my-0 print:py-0"
                >
                  <div className="flex items-center flex-col w-fit pt-4 pb-4">
                    <h2 className="text-2xl font-bold dark:text-slate-200">
                      The Outcome
                    </h2>
                    <div className="w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-1 rounded-full"></div>
                  </div>
                  {renderRichText(project.contentOutcome, options)}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="m-4 w-auto md:w-64 lg:flex lg:w-auto lg:max-w-xs xl:w-auto xl:max-w-xs top-16  self-end lg:self-start justify-between xl:justify-end flex-col lg:sticky lg:h-[calc(100vh-4rem)] lg:my-0 lg:mr-8 lg:ml-0 xl:flex-1 items-end flex">
            <div
              className={`hidden lg:flex xl:hidden overflow-y-auto  ${
                currentHeader ? "lg:animate-fadeIn" : "invisible"
              }`}
            >
              <CurrentProject project={project} currentHeader={currentHeader} />
            </div>

            <div
              ref={nextProject}
              className={`flex ${
                currentHeader > 1 ? "animate-fadeInSlideUp" : "hidden"
              }`}
            >
              <NextProject current={project} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PortfolioView;
